<template>
    <header>
        <div class="navi_top">
            <h1><img src="../assets/img/content/logo_2.png"></h1>
        </div>
        <div class="gnb">
            <ul>
                <li :class="listActive(vmdMenu)">
                    <!-- Dev : 클릭시 sub_wrap 오픈 -->
                    <div class="depth1" role="button">
                        <a :class="current(item)" v-for="item in vmdMenu" href="javascript:;" @click="redirect(item.link)">{{item.title}}</a>
                    </div>
                </li>
                <li :class="listActive(serviceMenu)">
                    <div class="depth1 has_depth2" role="button" :class="{'is_active' : gnbOpen}" @click="openDepth()">
                        <a href="javascript:;">KT 상품 체험존
                          <div class="arw_area">
                            <img class="active_arw" v-if="listActive(serviceMenu)" src="../assets/img/common/ic_arw.png" alt="">
                            <img v-if="!listActive(serviceMenu)" src="../assets/img/common/ic_down_arw_.png" alt="">
                          </div>
                        </a>

                    </div>
                    <div class="sub_wrap" :class="{'is_active' : gnbOpen}">
                        <ul>
                            <li>
                                <a :class="current(item)" v-for="item in serviceMenu" href="javascript:void(0)" @click="redirect(item.link)">{{item.title}}</a>
<!--                                <a class="is_current" href="javascript:void(0)" @click="redirect('/mainVideo')">메인 영상 관리</a>
                                <a href="javascript:void(0)" @click="redirect('/subMenu/hotDeal')">서브 메뉴 관리</a>
                                <a href="javascript:void(0)" @click="redirect('/subMenu/hotDeal')">우리매장핫딜</a>
                                <a href="javascript:void(0)" @click="redirect('/subMenu/halfPrice')">반값초이스</a>
                                <a href="javascript:void(0)" @click="redirect('/subMenu/cards')">제휴카드</a>
                                <a href="javascript:void(0)" @click="redirect('/subMenu/products')">제휴상품</a>-->
                            </li>
                        </ul>
                    </div>
                </li>
                <li :class="listActive(smartMenu)">
                    <div class="depth1">
<!--                        <a href="javascript:;" @click="redirect('/smart')">스마트 모바일 체험대</a>-->
                        <a :class="current(item)" v-for="item in smartMenu" href="javascript:;" @click="redirect(item.link)">{{item.title}}</a>
                    </div>
                </li>
              <li :class="listActive(samsung)">
                <div class="depth1">
                  <a :class="current(item)" v-for="item in samsung" href="javascript:;" @click="redirect(item.link)">{{item.title}}</a>
                </div>
              </li>
              <li :class="listActive(monitorMenu)">
                <div class="depth1">
                  <a :class="current(item)" v-for="item in monitorMenu" href="javascript:;" @click="redirect(item.link)">{{item.title}}</a>
                </div>
              </li>
            </ul>
        </div>
        <div class="navi_foot">
            <div class="login_info">
                <button @click="logout" type="button" class="btn_logout">로그아웃</button>
            </div>
        </div>
    </header>
</template>

<script>

export default {
    name: "newAdminHeader",
    data() {
        return {
            gnbOpen: true,
            vmdMenu: [{title: '태블릿 VMD', link: '/channel'}],
            serviceMenu: [{title: '메인 영상 관리', link: '/mainVideo'},
                // {title: '우리매장핫딜', link: '/subMenu/hotDeal'},
                {title: '반값초이스', link: '/subMenu/halfPrice'},
                {title: '소상공인 & Biz', link: '/subMenu/biz'},
                {title: '제휴상품 & 요금제', link: '/subMenu/products'},
                {title: '제휴카드', link: '/subMenu/cards'},],
            smartMenu: [{title: '스마트 모바일 체험대_애플', link: '/smart'},],
            samsung: [{title: '스마트 모바일 체험대_삼성', link: '/samsung'},],
            monitorMenu: [{title: '서브 모니터 VMD', link:'/monitorvmd'},]
        }
    },
    methods: {
        logout() {
            this.$store
                    .dispatch("LOGOUT")
                    .then((msg) => {
                        this.$router.push('/login');
                    })
                    .catch((err) => {
                        console.log(err);
                    })
        },
        openDepth() {
            this.gnbOpen = !this.gnbOpen;
        },
        current(item){
            const path = this.$route.path;
            if(item.link === path){
                return 'is_current';
            }
            return '';
        },
        listActive(menus){
            const path = this.$route.path;
            const find = menus.find(item => path.indexOf(item.link) >= 0);
            //this.gnbOpen = !path.indexOf('/subMenu') >= 0;
            if(find){
                return 'is_active';
            }
            return '';
        }
    },
    created() {
        const path = this.$route.path;
        if(path.indexOf('/mainVideo') >= 0 || path.indexOf('/subMenu') >= 0){
            this.gnbOpen = false;
        }
    }
}
</script>

<style scoped>

</style>